.agent:not(a){
  background-image: url("/assets/agent.jpg");
  a{
    &:first-of-type{
      @extend .upperRow;
      width   : 350px;
      left    : 866px !important;
    }
    &:not(:first-of-type){
      top     : 266px;
      width   : 300px;
      height  : 300px;
    }
    &:nth-of-type(2){
      left    : 150px;
    }
    &:nth-of-type(3){
      left    : 500px;
    }
    &:last-of-type{
      left    : 856px;
    }
  }
}