:root{
  --top : 286px;
}

.noMarginNoPadding {
  padding           : 0;
  margin            : 0;
}

.fullScreen{
  width             : 100vw;
  height            : 100vh;
}

.upperRow{
  top               : 20px !important;
  height            : 60px !important;
}

body {
  @extend .fullScreen;
  @extend .noMarginNoPadding;
  align-items       : center;
  display           : flex;
  justify-content   : center;
  background-color  : black;
}

#root {
  @extend .fullScreen;
  @extend .noMarginNoPadding;
  max-width         : 1280px;
  max-height        : 800px;
}

main {
  width             : 100%;
  height            : 100%;
  background-size   : cover;
}

a {
  // background-color  : #00FF0077;
  border            : none;
  display           : block;
  position          : absolute;

  &.toHome{
    @extend .upperRow;
    left            : 105px;
    width           : 170px;
  }

  &.agent{
    @extend .upperRow;
    left            : 752px !important;
    width           : 465px !important;
  }
}

.inputPwd{
  // position: absolute
  top               : calc(var(--top) + 7px);
  left              : 405px;
  input{
    font-size       : 2.3rem;
    width           : 405px;
    border: 1px solid;
    text-align: center;
  }
  .p-icon {
    width           : 1.3rem;
    height          : 1.3rem;
  }
}

.typing{
  color             : grey;
}
.valid{
  color             : green;
}
.unvalid{
  color             : red;
}

.result{
  position          : absolute;
  font-size         : 3.3rem;
  top               : calc(var(--top) + 14px);
  left              : 827px;
  background-color: white;
}

.mask{
  background-color    : white;
  height              : 74px;
  left                : 400px;
  position            : absolute;
  top                 : var(--top);
  width               : 487px;
}