.demandeOK {
  background-image: url("/assets/demandeOK.jpg");
  display           : flex;
  justify-content   : center;
  align-items       : center;

  a {
    position        : initial;
    width           : 100%;
    height          : 100%;
  }
}