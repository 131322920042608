.home {
  background-image: url("/assets/home.jpg");
  a {
    width: 227px;
    height: 3.3rem;
    top: 635px;

    &:first-of-type {
      left: 127px;
    }

    &:nth-of-type(2) {
      left: 400px;
    }

    &:nth-of-type(3) {
      left: 675px;
    }

    &:nth-of-type(4){
      left: 950px;
    }
  }
}