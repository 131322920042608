.question4 {
  background-image: url("/assets/question4.jpg");
  button {
    position            : absolute;
    width               : 200px;
    height              : 234px;
    border              : none;
    // border: 3px solid green;
    background-color    : transparent;

    &.selected{
      background-color    : #bad3ab55;
      border              : 3px solid green;
      color               : white;
      font-size           : 5rem;
    }

    &:nth-of-type(1) {
      @extend .q4col1;
      @extend .q4row1;
    }

    &:nth-of-type(2) {
      @extend .q4col2;
      @extend .q4row1;
    }

    &:nth-of-type(3) {
      @extend .q4col3;
      @extend .q4row1;
    }

    &:nth-of-type(4) {
      @extend .q4col1;
      @extend .q4row2;
    }

    &:nth-of-type(5) {
      @extend .q4col2;
      @extend .q4row2;
    }

    &:nth-of-type(6) {
      @extend .q4col3;
      @extend .q4row2;
    }
  }

  .result {
    top: 407px;
    left: 1020px;
  }


}

.q4row1 {
  top: 193px;
}

.q4row2 {
  top: 443px;
}

.q4col1 {
  left: 293px;
}

.q4col2 {
  left: 547px;
}

.q4col3 {
  left: 797px;
}